import { env } from '$lib/env';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { dev } from '$app/environment';
import { isErrorResult, isNetworkError, isUnauthorizedError } from '$lib/gql/error';
import { invalidate } from '$app/navigation';
import { dependent } from '$lib/dependent';

Sentry.init({
  dsn: env.SENTRY_DSN,
  release: env.HOPS_BUILD_ID,
});

export const handleError = (async ({ status, error, event, message }) => {
  const errorId = crypto.randomUUID();

  if (isUnauthorized(error)) {
    await invalidate(`${dependent('user', 'me')}`);
    return {
      message: 'Unauthorized',
      errorId,
    };
  }

  Sentry.captureException(error, { extra: { event, errorId, status, message } });

  if (dev) {
    console.error('Unhandled error', { error });
  }

  return {
    message: 'Internal server error',
    errorId,
    errors: dev && hasToString(error) ? [error.toString()] : undefined,
  };
}) satisfies HandleClientError;

/**
 * Handle the somewhat common case of authorization expiring.
 */
const isUnauthorized = (error: unknown): boolean => {
  if (isNetworkError(error) && isUnauthorizedError(error)) {
    return true;
  }

  if (isErrorResult(error)) {
    return isUnauthorized(error.error);
  }

  return false;
};

const hasToString = (t: unknown): t is { toString: () => string } => {
  return typeof t === 'object' && t !== null && 'toString' in t;
};
