import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0,2,11];

export const dictionary = {
		"/": [~12],
		"/(dashboard)/invite": [~13,[2,3]],
		"/kolli": [33,[11]],
		"/kolli/what-is-kolli": [34,[11]],
		"/(dashboard)/layouts/full": [14,[2]],
		"/(dashboard)/layouts/two-col": [15,[2]],
		"/(dashboard)/org": [~16,[2]],
		"/(dashboard)/org/[org=org]": [17,[2,4]],
		"/(dashboard)/org/[org=org]/app": [18,[2,4]],
		"/(dashboard)/org/[org=org]/app/[app]": [19,[2,4,5]],
		"/(dashboard)/org/[org=org]/app/[app]/build": [20,[2,4,5,6]],
		"/(dashboard)/org/[org=org]/app/[app]/build/[build]": [21,[2,4,5,6]],
		"/(dashboard)/org/[org=org]/app/[app]/deployment": [22,[2,4,5,7]],
		"/(dashboard)/org/[org=org]/app/[app]/deployment/[deployment]": [23,[2,4,5,7]],
		"/(dashboard)/org/[org=org]/app/[app]/encrypt-secret": [24,[2,4,5]],
		"/(dashboard)/org/[org=org]/app/[app]/env": [25,[2,4,5,8]],
		"/(dashboard)/org/[org=org]/app/[app]/env/[env]": [26,[2,4,5,8]],
		"/(dashboard)/org/[org=org]/clusters": [27,[2,4,9]],
		"/(dashboard)/org/[org=org]/clusters/[cluster]": [28,[2,4,9]],
		"/(dashboard)/org/[org=org]/member": [29,[2,4,10]],
		"/(dashboard)/org/[org=org]/member/[member]": [~30,[2,4,10]],
		"/(dashboard)/org/[org=org]/settings": [31,[2,4]],
		"/(dashboard)/org/[org=org]/tokens": [32,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';